import request from '@/utils/request'

// 反馈管理 分页
export function feedbackPage(query) {
  return request({
    url: '/biz/app-feedback/page',
    method: 'get',
    params: query
  })
}

// 反馈管理 详情
export function feedbackInfo(query) {
  return request({
    url: '/biz/app-feedback/get',
    method: 'get',
    params: query
  })
}

// 反馈管理 处理
export function feedbackUpdate(data) {
  return request({
    url: '/biz/app-feedback/update',
    method: 'post',
    data
  })
}
